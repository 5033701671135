{
    "general": {
        "username": "Nome Utente",
        "password": "Parola D'ordine",
        "serial_number": "Numero di serie",
        "fleet": "Flotta",
        "general": "Generali",
        "alerts": "Avvisi",
        "reconnect": "Riconnettiti",
        "fleet_name": "Nome Flotta",
        "show_more": "Mostra di più",
        "show_less": "Mostra di meno",
        "from": "Da",
        "all_time": "Tutti i datti",
        "registration": "Targa",
        "vehicles": "Veicoli",
        "settings": "Impostazioni",
        "members": "Membri",
        "telematics_units": "Unita Telematiche",
        "cancel": "Anulla",
        "id": "ID",
        "close": "Chiuso",
        "alert_low_pressure": "Critica",
        "alert_high_temperature": "Avviso (Alta Temperatura)",
        "no_alert": "OK",
        "stale": "Stantio",
        "warning": "Avviso",
        "maintenance": "Manutenzione",
        "select_one": "Perfavore sciegliere uno",
        "email": "Email",
        "phone_number": "Numero di telefono",
        "kilometers": "Chilometri",
        "coming_soon": "Prossimamente",
        "distance": "Distanza",
        "celcius": "Centigrado",
        "farenheit": "Fahrenheit",
        "search": "Cerca",
        "dismiss": "Congedare",
        "confirm_delete": "Sei siguro che vuoi cancelare {value}?",
        "name": "Nome",
        "any": "Qualsiasi",
        "delete": "Eliminare",
        "language": "Lingua",
        "last_seen": "Ultima volta avistato",
        "type": "Tipo",
        "pressure": "Pressione",
        "cold_pressure": "Pressione al freddo",
        "last_time_seen": "L'ultima volta visto",
        "notes": "Appunti",
        "reports": "Rapporto",
        "admin": "Administratore",
        "archived": "Archiviato",
        "changelog": "Registro delle modifiche",
        "contacts": "Contatti",
        "date": "Data",
        "edit": "Modificare",
        "end": "Fine",
        "events": "Evento",
        "home": "Inizio",
        "imei": "IMEI",
        "include_inactive": "Includi Innativo",
        "last_time_seen_desc": "Ultima volta avistato",
        "member": "Membro",
        "no": "No",
        "no_data": "Nessun dato ricevuto",
        "none": "Nessuno",
        "organization": "Organizazione",
        "provider": "Fornitore",
        "role": "Ruolo",
        "sensor_id": "ID Sensore",
        "sim_card": "Carta SIM",
        "sort_by": "Ordinara per",
        "start": "Inizio",
        "update": "Aggiornamento",
        "yes": "Si",
        "emails": "Indirizzo Email1, Indirizzo Email2, ...",
        "grams": "grammi",
        "print": "Stampare",
        "environmental": "Ambientale",
        "unknown": "Sconosciuto",
        "not_set": "Non impostato",
        "iccid": "ICCID",
        "request_quote": "Richiedi un preventivo",
        "access_level": "Livello di accesso",
        "managed_by": "Gestito da {value}",
        "address": "Indirizzo",
        "checklist_faults": "{ count } Guasto identificato nella lista di controllo | { count } Difetti identificati nella lista di controllo",
        "checksum_calculator": "Calcolatore di somma di controllo",
        "checksum_calculator_prompt": "Aggiungere un sensore a un veicolo? Utilizza il {0}",
        "update_profile": "Aggiorna profilo",
        "signed_in_as": "Registrato come {value}",
        "vin": "VIN",
        "all": "Tutti",
        "confirm": "Confermare",
        "create": "Creare",
        "powered-by": "Powered by",
        "rl-automotive": "RL Automotive",
        "you": "Tu",
        "remote": "Remoto",
        "url": "URL",
        "timestamp": "Timestamp",
        "temperature": "Temperatura"
    },

    "colours": {
        "black": "Nero",
        "blue": "Blu",
        "green": "Verde",
        "yellow": "Giallo",
        "orange": "Arancione",
        "red": "Rosso"
    },

    "pages": {
        "manage_reports": "Getsisci Rapporto",
        "manage_sim": "Gestisci Scheda SIM",
        "edit_sim": "Modifficare la Scheda SIM"
    },
    "navbar": {
        "kit_menu": "Aprire menu kit",
        "user_menu": "Aprire menu utente",
        "vehicles": "Veicoli",
        "fleets": "Flotte",
        "sensors": "Sensori",
        "reports": "Rapporti",
        "kit": "Kit",
        "sim_cards": "Schede SIM",
        "telematics_boxes": "Scatole Telematiche",
        "settings": "Impostazioni",
        "logout": "Disconnettersi"
    },
    "landing": {
        "quick_actions": "Azioni Rapide",
        "add_vehicle": "Aggiungi Veicolo",
        "add_vehicle_prompt": "Aggiungi un veicolo per iniziare",
        "add_report": "Aggiungi Rapporto",
        "add_report_prompt": "Imposta un rapporto per ottenere aggiornamenti sulle tue flotte",
        "dashboard": "Panello",
        "sensors_on_vehicles": "Sensori sui veicoli",
        "sensors_on_vehicles_prompt": "Dei sensori è assegnato a un veicolo",
        "sensors_off_vehicles": "Sensori non collegati ai veicoli",
        "sensors_off_vehicles_prompt": "I sensori non sono assegnati a un veicolo. Questi sensori non saranno in grado di raccogliere dati",
        "vehicles_prompt": "Veicoli della tua organizzazione e di tutte le organizzazioni che gestisci",
        "latest_changes": "Ultime modifiche"
    },
    "login": {
        "sign_in_message": "Accedi al tuo account",
        "sign_in": "Registrazione",
        "incorrect_username_password": "Nome Utente o Password Errati"
    },

    "manage_vehicles": {
        "registration": "Targa",
        "imei": "IMEI",
        "fleet": "Flotta",
        "depot": "Deposito",
        "show_deactivated": "Mostra/nascondi i veicoli disattivati",
        "show_archived": "Mostra/nascondi i veicoli archiviati",
        "add_vehicle": "Aggiungi Veicolo",
        "total_vehicles": "Totale veicoli",
        "details": "Detagli",
        "no_imei": "Nessun IMEI",
        "alert_level": "Livello di avviso",
        "open_map": "Apri mappa",
        "no_results_subheading": "Nessun veicolo trovato con i criteri di ricerca selezionati",
        "no_vehicles": "Nessun veicolo",
        "no_vehicles_subheading": "Non ci sono veicoli da visualizzare. Per aggiungere un veicolo, fai clic sul pulsante Aggiungi veicolo."
    },
    "edit_vehicle": {
        "vehicles": "Veicoli",
        "overview": "Panoramica",
        "logs": "Registri",
        "checklist": "Lista di controllo",
        "mot_history": "Storia MOT"
    },
    "vehicle_overview": {
        "archived": "Archiviato",
        "deactivated": "Disattivato",
        "speed": "Velocita",
        "thresholds": "Solgie",
        "environmental": "Ambientale",
        "environmental_breakdown": "Ripartizione del rapporto ambientale",
        "euro_rating_title": "Normativa Euro non impostata",
        "euro_rating_subtitle": "Perfavore aggiungi la normativa Euro per questo veicolo",
        "sr_enter_edit_mode": "Accedi alla modalità di modifica per i dettagli del veicolo",
        "sr_exit_edit_mode": "Esci dalla modalità di modifica per i dettagli del veicolo",
        "type": "Tipo",
        "manufacturer": "Produttore",
        "engine_capacity": "Cilindrata",
        "revenue_weight": "Peso a pieno carico",
        "model": "Modello",
        "chassis_type": "Telaio",
        "fuel_type": "Tipo di carburante",
        "engine_type": "Tipo di motore",
        "mot_expiry": "Scadenza MOT",
        "fleet": "Flotta",
        "depot": "Deposito",
        "imei": "IMEI",
        "configuration": "Configurazione",
        "application": "Applicazione",
        "emergency_contact": "Contatto di Emergenza",
        "country": "Paese",
        "torque": "Coppia",
        "axle": "Asse",
        "sr_enter_edit_axle_mode": "Entra in modalità di modifica per l'asse",
        "sr_exit_edit_axle_mode": "Uscire dalla modalità di modifica per l'asse",
        "rim_size": "Dimensione del cerchione",
        "tyre_size": "Dimensione pneumatici",
        "minimum_weight": "Pesso Minimo",
        "maximum_weight": "Pesso Massimo",
        "recommended_pressure": "Pressione Consigliata ({unit})",
        "recommended_temperature": "Temeratura consigliata ({unit})",
        "maintenance_threshold": "Soglia di mantenimento",
        "warning_threshold": "Soglia di avviso",
        "critical_threshold": "Soglia Critica",
        "submit": "Invia",
        "sensors": "Sensori",
        "assigned_to": "Assegniato a",
        "last_seen": "Ultima Visualizzazione",
        "no_data": "Nessun dato riportato nell'arco temporale selezionato",
        "please_check": "Si prega di controllare i registri dei veicoli per ulteriori informazioni",
        "temp_over_time": "Temperatura/pressione del sensore nel tempo",
        "current_pressure": "Pressione attuale",
        "current_cold_pressure": "Attuale pressione fredda",
        "current_temp": "Temperatura attuale",
        "current_location": "Posizione Attuale",
        "kilometres_travelled": "Chilometri percorsi",
        "kilometres_per_hour": "Chilometri all'ora",
        "kilometers_hour": "kilometri/ora",
        "schematic_view": "Vista Schematica",
        "list_view": "Vista Elenco",
        "gps_unavailable": "GPS non disponibile",
        "battery_unavailable": "Batteria Non Disponibile",
        "vehicle_alerts": "Avvertismento Veicolo",
        "vehicle_alerts_description": "Desidero ricevere aggiornamenti da questo veicolo.",
        "recommended_tyre_make": "Marca di pneumatici consigliata",
        "recommended_tyre_pattern": "Modello di pneumatico consigliato",
        "rim_type": "Typo Cerchio",
        "tyre_condition": "Condizioni dei Pneumatici",
        "new_tread_depth": "Nuova Profondità di Filettatura",
        "add_config_error": "Fornisci a questo veicolo una configurazione per iniziare ad aggiungere sensori",
        "below_recommended": "sotto consigliato",
        "critical_threshold_description": "Soglia che attiverà lo stato critico",
        "danger_level": "Livello di pericolo ({unit})",
        "maintenance_level": "Livello di manutenzione ({unit})",
        "maintenance_threshold_description": "Soglia che attiverà lo stato di manutenzione",
        "no_sensors": "Non ci sono sensori attualmente collegati a questo veicolo",
        "warning_level": " Livello di avviso ({unit})",
        "warning_threshold_description": "Soglia che attiverà lo stato di avviso",
        "tyre_make": "Modello Veicolo",
        "tyre_pattern": "Modello di pneumatici",
        "estimated_co2": "Emissioni totali stimate di CO2 (kg)",
        "gps_fix_unavailable": "Nessun GPS disponibile",
        "total_emissions": "Emissioni totali",
        "alerts": "Avvisi",
        "general": "Generali",
        "settings": "Impostazioni",
        "unarchived": "Non Archiviato",
        "deactivate_vehicle": "Deactivate Vehicle"
    },
    "attach_sensor_modal": {
        "create_title": "Creare un nuovo sensore in posizione",
        "id": "ID",
        "type": "Tipo",
        "create_sensor": "Creare e colegare un sensore",
        "attach_title": "Selezionare un sensore a cui colegarsi",
        "no_unassigned_sensors": "Nessun sensore sconosciuto trovato",
        "no_unassigned_subheading": "Rimuovi un sensore da un veicolo o fai clic sul pulsante più per crearne uno nuovo",
        "show_assigned": "Mostra sensori assegniati",
        "attach_sensor": "Collegare Sensori",
        "checksum_calculator": "Somma di controllo",
        "missing_sensor": "Senza Sensori"
    },
    "vehicle_logs": {
        "limit": "Limite",
        "sort": "Ordinare",
        "newest": "Piu Recente",
        "oldest": "Piu Antico",
        "filter": "Filtro",
        "any": "Qualunque",
        "decode_success": "Decodificare confermato",
        "decode_error": "Errore di Decodificazione",
        "configuration_error": "Errore di Configirazione",
        "since": "Da",
        "now": "Adesso",
        "reason": "Motivo",
        "sensor_id": "ID Sensore",
        "temperature": "Temperatura",
        "pressure": "Pressione",
        "speed": "Velocita",
        "distance": "Distanza",
        "no_logs": "Nessun registro da visualizzare",
        "no_logs_subheading": "Perfavore verificare le impostazioni dell veicolo",
        "tpm_success_message": "TPM compresso correttamente analizzato",
        "speed_dist_success_message": "Pacchetto velocità e distanza analizzato correttamente",
        "sensor_data_error_message": "Errore durante la decodifica del pacchetto",
        "lantronix_data_error_message": "Perfavore verificare le impostazioni de le unita telematiche",
        "sensor_handshake_message": "Stretta di mano decodificata con successo",
        "sensor_error_message": "Qualcosa è andato storto (errore sensore non definito)",
        "unrecognized_external_sensor_pakcet": "Pacchetto sensore esterno non riconosciuto",
        "sensor_flag_set": "Flag sensore impostato, non sono stati ricevuti dati negli ultimi 60 minuti"
    },
    "vehicle_attributes": {
        "co2_emissions": "Emissioni di CO2(g/km)",
        "type_approval": "Tipo di approvazione",
        "euro_status": "Normativa Euro"
    },
    "new_vehicle": {
        "add_vehicle": "Aggiungi Veicolo",
        "basic_information_title": "Informazioni di Base",
        "basic_information_prompt": "Informazioni di Base sull Veicolo",
        "registration": "Targa",
        "check_dvla": "Controlla DVLA",
        "dvla_duplicate_vehicle": "Esiste già un veicolo con questa immatricolazione",
        "manufacturer": "Produttore",
        "model": "Modello",
        "depot": "Deposito",
        "depots": "Depositi",
        "map": "Mappa",
        "missing_sensor": "Sensori Mancanti",
        "sensors": "Sensori",
        "engine_type": "Tipo di Motore",
        "fuel_type": "Tipo di Carburante",
        "mot_expiry": "Scadenza MOT",
        "chassis_type": "Tipo di telaio",
        "vehicle_type": "Tipo di veicolo",
        "imei": "IMEI",
        "torque": "Coppia",
        "application": "Applicazione",
        "sensor_information_title": "Informazioni Sensori",
        "sensor_information_prompt": "Informazioni su Quanti Sensori e Quale Tipo",
        "configuration": "Configurazione",
        "sensor_manifest": "Manifesto dell Sensore",
        "create_without_sensors": "Crea senza sensori",
        "create_with_sensors": "Crea con i sensori:",
        "fleets": "Flotte",
        "list": "Veicoli"
    },
    "manage_fleets": {
        "add_fleet_contact": "Aggiungi contatto a {fleet}",
        "contact_name": "Nome contatto",
        "add_contact": "Aggiungere Contatto",
        "add_fleet": "Aggiungere Una Flotta",
        "total_fleets": "Flotte totali",
        "defaults": "Predefinito",
        "created_on": "Creata al { date }",
        "defaults_prompt": "Questi valori verranno utilizzati come soglie predefinite per tutti i nuovi veicoli creati in questa flotta.",
        "create_depot_prompt": "Aggiungere un depositito a",
        "depots": "Depositi",
        "add_depot": "Aggiungere deposito",
        "no_depots": "Nessun deposito",
        "subscribe_prompt": "Inscriviti a tutti i veicoli in questa flotta",
        "unsubscribe_prompt": "Annulla l'iscrizione a tutti i veicoli in questa flotta",
        "subscribe": "iscriviti",
        "unsubscribe": "cancella l'iscrizione",
        "no_depots_prompt": "La flotta non ha depositi premere  aggiungere un deposito per iniziare",
        "sensors_prompt": "Questi sensori sono gia assegnati a un veicolo in questa flotta",
        "no_fleets_prompt": "Questa organizatione non contiene flotte per visualizzare",
        "no_fleets": "Nessuna flotta",
        "duplicate_fleet_error": "Una flotta con quel nome esiste già",
        "duplicate_depot_error": "Un deposito con quel nome esiste già",
        "confirm_delete_depot_modal_text": "Sei sicuro che vuoi eliminare questo {depot}?",
        "colour": "Colore",
        "definition": "Definizione",
        "black_definition": "Nessun dato",
        "blue_definition": "Nessun dato ne le ultime 12 ore",
        "green_definition": "Nessun problema",
        "yellow_definition": "Sotto soglia di manutenzione",
        "orange_definition": "Sotto soglia di avviso",
        "red_definition": "Sotto soglia critica",
        "subscribe_failed": "Impossibile iscriversi a questa flotta, riprova più tardi",
        "subscribe_success": "Iscritto a tutti i veicoli in questa flotta",
        "unsubscribe_failed": "Impossibile annullare l'iscrizione a questa flotta, riprova più tardi",
        "unsubscribe_success": "Annullato l'iscrizione a tutti i veicoli in questa flotta",
        "confirm_delete_fleet_modal_text": "Sei siguro che vuoi cancellare questa flotta: {fleet}?",
        "delete_fleet": "Cancellare Flotta",
        "delete_failed": "Impossibile eliminare la flotta, riprova più tardi",
        "empty_prompt_header": "Tutti i vehicoli coretamente configurati!",
        "empty_prompt_subtitle": "Non esitono veicoli in questa flotta che mancano sensori!"
    },
    "sensors": {
        "add_sensor": "Aggiungere Un Sensore",
        "bound": "Limite",
        "position": "Posizione",
        "on_vehicle": "In Veicolo",
        "not_on_vehicle": "Manca sull Veicolo",
        "no_vehicle": "Nessun Veicolo",
        "no_wheel": "Nessuna ruota",
        "updated_at": "Aggiornato a",
        "same_id_error": "Unsensore con lo stesso ID gia preasente",
        "no_reg_error": "Non e stato possibile trovare nessun veicolo con questa targa",
        "axle_number": "Numero Dell' Asse",
        "wheel_number": "Numero di ruota",
        "no_data_recorded": "Nessun dato registratto",
        "failed_to_load": "Impossibile caricare i sensori"
    },
    "reports": {
        "add_report": "Aggiungi Rapporto",
        "reports": "rapporto",
        "add report": "aggiungi rapporto",
        "schedule": "Programma",
        "send_now": "Manda adesso",
        "edit_report": "Edittare Raporti",
        "emails": "Indirizzi Email",
        "language": "Lingua",
        "ima": "Ispezione Pre-Manutenzione (PMI)",
        "km": "Kilometragio (KM)",
        "co2": "Emissioni di CO2",
        "maintenance": "Manutenzione",
        "vehicle_list": "Elenco veicoli",
        "basic_information": "Informazioni di Base",
        "basic_information_prompt": "Seleziona il tipo di rapporto che desideri creare e inserisci i dettagli richiesti",
        "frequency": "Frequenza",
        "frequency_prompt": "Seleziona la frequenza con cui desideri inviare il rapporto",
        "day": "Giorno",
        "daily": "Giornaliero",
        "hour": "Ora",
        "unable_to_load": "Impossibile caricare i rapporti",
        "error_sending": "Errore nell'invio del rapporto",
        "delete_error": "Impossibile eliminare il rapporto, riprova più tardi"
    },
    "organization_settings": {
        "no_profile_picture": "Nessuna immagine del profilo",
        "setup_heading": "Personalizazione avansata organizatione",
        "setup_body": "La personalizzazione avanzata dell'organizzazione, inclusi loghi e personalizzazione dei temi, è disponibile su richiesta a RL Automotive.",
        "successfully_updates": "Impostazioni aggiornate con successo",
        "alerts_subheading": "Veicoli a qui quest {username} riceverà avvisi",
        "no_vehicles_content": "Questo utente non riceverà avvisi da nessun veicolo"
    },
    "sim": {
        "add_sim": "Aggiungi Carta SIM",
        "search_iccid": "Cerca ICCID",
        "iccid_prompt": "L'identificativo univoco della carta SIM",
        "msisdn_prompt": "Il numero di telefono di 10-14 cifre compreso il prefisso internazionale (ad es. 44 nel Regno Unito)",
        "provider": "Fornitore",
        "send_sms": "Inviare SMS",
        "send_sms_prompt": "Inviare SMS a",
        "message": "Messagio",
        "sms_sent": "Messaggio inviato",
        "imsi_description": "Numero ISDN della stazione mobile.",
        "provider_description": "Provider che ha emesso la scheda SIM. Se il tuo provider SIM non è elencato, contatta l'assistenza.",
        "unable_to_delete": "Impossibile eliminare la scheda SIM, riprova più tardi",
        "unable_to_edit": "Impossibile modificare la scheda SIM, riprova più tardi",
        "failed_to_create": "Impossibile creare la scheda SIM, riprova più tardi"
    },
    "telematics": {
        "add_telematics": "Aggiungere Scatolo Telematica",
        "search_imei": "Cerca IMEI",
        "software": "Software",
        "hardware": "Hardware",
        "main_port": "Porto Principale",
        "accessory_port": "Porto di riserva",
        "device_name": "Nome Dispositivo",
        "edit_telematics": "Modifica unità telematica",
        "no_update_information": "Nessuna informazione sull'aggiornamento",
        "remove": "Rimuovere l'unità telematica",
        "failed_to_load": "Impossibile caricare le informazioni sull'unità telematica"
    },
    "settings": {
        "profile": "Profilo",
        "old_password": "Vecchia Password",
        "new_password": "Nuova Password",
        "confirm_password": "Conferma Password",
        "update_password": "Aggiornare la Password",
        "profile_prompt": "Cambia la modalità di visualizzazione delle informazioni sul sito web. Le unità di lingua e temperatura selezionate qui si rifletteranno anche nei rapporti, ad es. Rapporti dell'IMA.",
        "first_name": "Nome",
        "last_name": "Cognome",
        "password": "Password",
        "locale": "Locale",
        "locale_prompt": "Cambia la modalità di visualizzazione delle informazioni sul sito web. Le unità di lingua e temperatura selezionate qui si rifletteranno anche nei rapporti, ad es. Rapporti dell'IMA.",
        "language": "Lingua",
        "pressure_unit": "Unita di Pressione",
        "temperature_unit": "Unita di Temperatura",
        "time_zone": "Fuso Orario",
        "coming_soon": "(prossimamente)",
        "update_settings": "Aggiornare le Impostazioni",
        "test_sms": "Invia un SMS di prova",
        "alerts": "Avvisi",
        "no_alerts": "Nessun avviso",
        "alerts_subtitle": "Riceverai rapporti da questi veicoli",
        "no_active_subscriptions_placeholder": "Non hai abbonamenti attivi. Gli abbonamenti possono essere attivati ​​nel menu del veicolo.",
        "organization_subtitle": "Impostazioni e informazioni relative alla tua organizzazione.",
        "add_user": "Aggiungi Utente",
        "username_error": "Questo nome utente è già stato alocato",
        "email_error": "Questo indirizzo email è già stato alocato",
        "current_organization": "Organizzazione Corrente(Default)",
        "confirm_delete_user_modal_text": "Sei siguro che vuoi cancelare questo user: {user}?"
    },
    "errors": {
        "generic_unexpected_error": "Si è verificato un errore imprevisto",
        "failed_to_load_alert": "Impossibile caricare la pagina dei veicoli, riprova più tardi",
        "failed_to_update_telematics_unit": "Impossibile aggiornare l'unità telematica",
        "failed_to_load_telematics_units": "Impossibile caricare le unità telematiche, riprova più tardi",
        "failed_to_create_telematics_unit": "Impossibile creare l'unità telematica, riprova più tardi",
        "failed_to_delete_telematics_unit": "Impossibile eliminare l'unità telematica, riprova più tardi",
        "update_profile_error": "An unexpected error occurred when updating profile",
        "delete_user_error": "Impossibile eliminare l'utente, riprova più tardi"
    },
    "delete_sensor_modal": {
        "delete_message": "Questa azione è irreversibile e cancellerà definitivamente questo sensore dalla piattaforma. Se desideri scollegare il sensore per riutilizzarlo, annulla questa operazione e seleziona invece il pulsante di scollegamento."
    },
    "not_found_page": {
        "subtitle": "Controlla l'URL nella barra degli indirizzi e riprova.",
        "title": "Paggina non trovata",
        "return_home": "Torna alla pagina iniziale"
    },
    "application": {
        "logistics": "Logistica",
        "mixed_service": "Servizio Misto",
        "waste": "Rifiuti",
        "food_distribution": "Distribuzione di cibo",
        "food_waste": "Riffuti di cibo",
        "psv": "PSV",
        "off_highway": "Fuori Strada",
        "urban_last_mile": "Ultimo  / Miglio Urbano",
        "other": "Altro"
    },
    "fuel": {
        "diesel": "DIESEL",
        "petrol": "BENZINA",
        "electric": "ELETTRICO",
        "hybrid": "IBRIDO",
        "biofuel": "BIOCARBURANTE",
        "hydrogen_fuel_cell": "CELLA A COMBUSTIBILE A IDROGENO",
        "compressed_natural_gas": "GAS NATURALE COMPRESSO",
        "liquified_petroleum_gas": "GAS PETROLIFERO LIQUEFATTO",
        "non_applicable": "N/A"
    },
    "vehicle_type": {
        "bus": "Bus",
        "car": "Auto",
        "crane": "Gru",
        "digger": "Escavatore",
        "dumper": "Dumper",
        "loader": "Caricatore",
        "tractor": "Trattore",
        "trailer": "Rimorchio",
        "truck": "Camion",
        "truck_trailer": "Camion Rimorchio",
        "tug": "Veicolo rimorchiatore",
        "van": "Furgone"
    },
    "days": {
        "monday": "Lunedi",
        "tuesday": "Martedi",
        "wednesday": "Mercoledi",
        "thursday": "Giovedi",
        "friday": "Venerdi",
        "saturday": "Sabato",
        "sunday": "Domenica"
    },
    "vehicle_checklist": {
        "checklist": "Lista di controllo",
        "issued_by": "Emesso da",
        "created_at": "Creato a",
        "brakes": "Freni",
        "horn_steering": "Clacson/sterzo",
        "lights": "Luci",
        "mirror_glass": "Specchio/Vetro",
        "seat_belts": "Cinture di sicurezza",
        "washers_wipers": "Lavavetri/Tergicristalli",
        "battery": "Batteria",
        "fluids_fuel_oil": "Liquidi/Carburante/Olio",
        "bodywork_doors": "Carrozzeria/Porte",
        "exhaust": "Scarico",
        "tyres_wheels": "Pneumatici/Ruote",
        "load": "Carico",
        "towbar_taillift": "Gancio di traino/Sollevatore posteriore",
        "van_check": "Controllo furgone",
        "previous_recordings": "Registrazioni precedenti",
        "faults": "Difetti",
        "no_recordings": "Nessuna precedente registrazione",
        "mirrors_cameras_and_glass": "Specchi, telecamere e vetri",
        "windscreen_wipers_and_washers": "Tergicristalli e lavavetri",
        "warning_lights": "Luci di avvertimento",
        "steering_and_electronic_stability_control": "Sterzo e controllo elettronico della stabilità",
        "horn": "Clacson",
        "breaks_and_air_build": "Freni e costruzione dell'aria",
        "height_marker": "Indicatore di altezza",
        "seat_belts_and_cab_interior": "Cinture di sicurezza e interno della cabina",
        "security_and_condition_of_cab_doors_steps": "Sicurezza e condizioni delle porte e dei gradini della cabina",
        "brake_lines": "Linee di freno",
        "electrical_connections": "Connessioni elettriche",
        "coupling_security": "Sicurezza di accoppiamento",
        "load_security": "Sicurezza del carico",
        "number_plate": "Targa",
        "reflectors_and_lights": "Riflettori e luci",
        "markers": "Marcatori",
        "ancillary_equipment": "Attrezzatura ausiliaria",
        "lights_indicators_and_side_repeaters": "Luci, indicatori e ripetitori laterali",
        "fuel_oil_leaks": "Perdite di carburante e olio",
        "security_and_condition_of_body_wings": "Sicurezza e condizioni delle ali del veicolo",
        "battery_security_and_condition": "Sicurezza e condizioni della batteria",
        "diesel_exhaust_fluid_adblue": "Liquido di scarico diesel (AdBlue)",
        "excessive_engine_exhaust_smoke": "Fumo eccessivo dal motore",
        "aleternative_fuel_systems_and_isolation": "Sistemi di carburante alternativi e isolamento",
        "spray_suppression": "Suppressione della nebbia",
        "tyres_and_wheel_fixing": "Pneumatici e fissaggio della ruota",
        "high_voltage_emergency_cut_off_switch": "Interruttore di emergenza ad alta tensione",
        "no_notes": "Non cxi sonno note per questo veicolo",
        "checklists_not_supported": "Le liste di controllo non sono supportate per questo veicolo",
        "cab_mirrors_seatbelts_glass": "Specchi, cinture di sicurezza e vetri",
        "horn_and_gauges": "Clacson e misuratori",
        "turn_signals": "Segnali di svolta",
        "backup_lights_and_alarm": "Luci di retroilluminazione e allarme",
        "fire_extinguisher_condition": "Condizione dell'estintore",
        "windshield_wipers_and_fluid": "Tergicristalli e liquido",
        "fuel_levels": "Livelli di carburante",
        "engine_oil": "Olio motore",
        "transmission_fluid": "Liquido di trasmissione",
        "brake_fluid": "Liquido dei freni",
        "cooling_system_fluid": "Liquido del sistema di raffreddamento",
        "oil_leak_lube": "Perdita di olio / lubrificante",
        "brake_condition": "Condizione dei freni",
        "tyres_or_tracks": "Pneumatici o cingoli",
        "hydraulic_oil": "Olio idraulico",
        "hose_condition": "Condizione dei tubi di colegamento",
        "coupling_devices_and_connectors": "Dispositivi di accoppiamento e connettori",
        "exhaust_system": "Sistema di scarico",
        "blade_bloom_ripper_condition": "Condizione della lama / fioritura / rompitratta",
        "licence_lamp": "Luci targa",
        "fift_wheel_grease": "Grasso ralla",
        "electrical_connections_and_terminals": "Connessioni e terminali elettrici",
        "dot_reflective_markings": "Marchi riflettenti DOT",
        "access_ramps": "Rampe di accesso",
        "brake_lamps_and_reflectors": "Luci e riflettori dei freni",
        "fasteners": "Elementi di fissagio",
        "ragged_sharp_edges_dammage_body_condition": "Condizione del corpo / danni ai bordi taglienti",
        "trailer_sides_roof_damage": "Lati del rimorchio / danni al tetto",
        "latches_gates_opening_devices": "Chiusure / cancelli / dispositivi di apertura",
        "safety_kits": "Kit di sicurezza",
        "adr_kit": "Kit ADR",
        "fridge_unit": "Unità frigorifera",
        "fridge_unit_fuels": "Carburanti per unità frigorifera",
        "straps": "Cinghie",
        "tailift_condition": "Condizione del sponda idraulica",
        "tailift_operation": "Funzionamento del sponda idraulica",
        "bumpers_guards": "Paraurti / protezioni",
        "additional_lamps": "Luci aggiuntive",
        "truck_and_trailer_cables_brake_connections": "Cavi del camion e del rimorchio / connessioni dei freni",
        "air_suspension_leaks": "Perdite di sospensione pneumatica",
        "spare_wheel_and_carrier": "Ruota di scorta e portaruota",
        "hooses_and_pipes": "Tubi e tubi",
        "tyre_thread_depth": "Profondità del battistrada",
        "tyre_condition": "Condizione del pneumatico",
        "return_to_checklists": "Torna alle liste di controllo",
        "reset": "Rissetare",
        "tachograph": "Tachigrafo",
        "adblue_level_ok": "Livello AdBlue OK",
        "steering": " Servo Sterzo",
        "fuel_filter_cap": "Tappo del filtro del carburante",
        "cameras": "Telecamere",
        "load_secure_restraints": "Carico sicuro / vincoli",
        "doors": "Porte",
        "loading_bar": "Barra di caricamento",
        "three_tacho_rolls": "Tre rotoli tachigrafo",
        "sack_truck": "Carrello",
        "wipes": "Panni",
        "first_aid_kit": "Kit di primo soccorso",
        "fire_extinguisher": "Estintore",
        "breakdown_booklet": "Libretto di guasto",
        "incident_form": "Modulo di incidente",
        "safety_check_sheet": "Scheda di controllo della sicurezza",
        "van_dimensions": "Dimensioni del furgone",
        "no_valuables_on_sight": "Nessun valore a vista",
        "fuel_on_return": "Nivello carburante al ritorno",
        "fuel_reciept": "Rivevuta del carburante",
        "attach_image": "Aggiungere Immagine",
        "media": "Media"
    },
    "dvla": {
        "no_registration": "Perfavore inserisci una targa per poter controllare il veicolo",
        "failed_to_create": "Impossibile creare il veicolo, riprova più tardi",
        "not_found_error": "Impossibile trovare il veicolo, riprova più tardi"
    },
    "tyre_condition": {
        "new": "Nuovo",
        "re-mould": "RIGENERATO",
        "re-grooved": "RIFRETTATO"
    },
    "mot_history": {
        "completed_date": "Complatato il",
        "expiry_date": "Scadenza",
        "odometer_value": "Valore del contachilometri",
        "test_number": "Testo Numero",
        "no_vehicle_found_error": "Impossibile trovare il veicolo, riprova più tardi"
    },
    "abbreviations": {
        "kilobytes": "KB"
    },
    "footer": {
        "powered_by_text": "Generato da RL Automotive"
    },
    "telematics-unit-vehicle-create-modal": {
        "imei-subtitle": "IMEI devono contenere solo numeri",
        "no-sim-card": "Nessuna carta SIM",
        "create-sim-card-subtitle": "Se non puoi seletare una Sim Card, per favore creane una nuova"
    }
}
