{
    "general": {
        "submit": "Onayla",
        "imei": "IMEI",
        "powered-by": "Tarafından desteklenmektedir",
        "access_level": "Erişim düzeyi",
        "managed_by": "Tarafından yönetilmektedir",
        "clear-screen": "Ekranı temizle",
        "reconnect": "Yeniden bağlan",
        "general": "Genel",
        "alerts": "Alarmlar",
        "sim_card": "Sim kart",
        "rl-automotive": "RL Automotive",
        "serial_number": "Seri numarası",
        "you": "Sen",
        "remote": "Uzak",
        "contacts": "İletişim",
        "address": "Adres",
        "readings": "Okuyor",
        "provider": "Sağlayıcı",
        "show_more": "Daha fazla göster",
        "next": "Sonraki",
        "previous": "Önceki",
        "checklist_faults": "{ count } Kontrol listesinde tanımlanan hata | { count } Kontrol listesinde tanımlanan hatalar",
        "show_less": "Daha az göster",
        "checksum_calculator": "Kontrol sonucu hesaplayıcısı",
        "checksum_calculator_prompt": "Bir araca sensör mü ekliyorsunuz? {0} kullanın",
        "date": "Tarih",
        "role": "Rol",
        "sort_by": "Sırala",
        "alert_low_pressure": "Kritik",
        "alert_high_temperature": "Yüksek sıcaklık alarmı",
        "warning": "Uyarı",
        "warnings": "Uyarı Yok | { count } Uyarı | { count } Uyarılar",
        "maintenance": "Tamir",
        "unassigned": "Atanmamış",
        "stale": "Bozuk",
        "print": "Yazdır",
        "update_profile": "Profili güncelle",
        "signed_in_as": "Oturum açıldı",
        "vin": "VIN",
        "all": "Tümü",
        "no_alert": "OK",
        "sensor_id": "Sensör ID",
        "telematics_units": "Telematik Üniteleri",
        "start": "Başlangıç",
        "all_time": "Tüm zamanlar",
        "last_seen": "Son görülme",
        "confirm": "Onayla",
        "create": "Oluştur",
        "from": "İtibaren",
        "admin": "Yönetici",
        "member": "Üye",
        "last_time_seen": "Son görülme zamanı",
        "last_time_seen_desc": "Son görülme zamanı (azalan)",
        "include_inactive": "Aktif olmayanları dahil et",
        "end": "Bitiş",
        "home": "Başlangıç",
        "edit": "Düzenle",
        "reports": "Raporlar",
        "kilometers": "Kilometreler",
        "distance": "Mesafe",
        "organisation": "Organizasyon",
        "organisations": "Organizasyonlar",
        "events": "Olaylar",
        "changelog": "Değişiklik kaydı",
        "environmental": "Çevresel",
        "no_data": "Veri alınamadı",
        "notes": "Notlar",
        "update": "Güncelleme",
        "none": "Hiç",
        "unknown": "Bilinmeyen",
        "not_set": "Ayarlanmadı",
        "username": "Kullanıcı adı",
        "password": "Şifre",
        "fleet": "Filo",
        "fleet_name": "Filo ismi",
        "registration": "Plaka",
        "vehicles": "Araçlar",
        "settings": "Ayarlar",
        "cancel": "İptal et",
        "id": "ID",
        "close": "Kapalı",
        "select_one": "Lütfen bir tane seçin",
        "email": "E-posta",
        "phone_number": "Telefon numarası",
        "celcius": "Celsius",
        "coming_soon": "Yakında",
        "farenheit": "Fahrenheit",
        "search": "Aranıyor...",
        "dismiss": "Reddet",
        "confirm_delete": "{value} değerini silmek istediğinizden emin misiniz?",
        "name": "İsim",
        "any": "Herhangi",
        "delete": "Sil",
        "language": "Dil",
        "type": "Tip",
        "yes": "Evet",
        "no": "Hayır",
        "archived": "Arşivlendi",
        "pressure": "Basınç",
        "cold_pressure": "Soğuk Basınç",
        "iccid": "ICCID",
        "emails": "E-posta1, Eposta2, ...",
        "grams": "gram",
        "request_quote": "Teklif iste",
        "members": "Üyeler",
        "url": "URL",
        "latitude": "Enlem",
        "longitude": "Boylam",
        "timestamp": "Zaman damgası",
        "temperature": "Sıcaklık",
        "no_results": "Sonuç bulunamadı",
        "no_results_subheading": "Arama kriterlerinize uyan sonuç bulunamadı",
        "refine_search": "Lütfen arama kriterlerinizi daraltın",
        "refine_search_subheading": "Bir araç plakası arayın veya belirli bir Filo/Depo seçin",
        "created_on": "Oluşturuldu",
        "created_on_date": "{ tarih } tarihinde oluşturuldu",
        "registration_id": "Plaka/ID",
        "position": "Pozisyon",
        "vehicle": "Araç",
        "download": "İndir",
        "wheel_position": "Teker pozisyonu",
        "select_your_option": "Seçeneğinizi seçin",
        "are_you_sure": "Emin misin?"
    },
    "organisation-hierachy": {
        "create-organisation": "Organizasyon oluştur",
        "create-organisation-modal-subtitle": "Organizasyonlar, müşteriler ve kullanıcılar için ayrım sağlamak için kullanılır. Bir alt organizasyon oluşturmak, araçlara erişimi sınırlama yeteneği sağlar."
    },
    "fleet-list": {
        "tyre-status": "Lastik durumu"
    },
    "fleet_pmi_report": {
        "heading": "Bakım öncesi denetim verileri. Yüklenmesi biraz zaman alabilir",
        "wheel_position": "Teker pozisyonu",
        "alert_type": "Alarm tipi",
        "pressure": "Basınç (PSI)",
        "cold_pressure": "Soğuk Basınç (PSI)",
        "recommended_pressure": "Önerilen Basınç (PSI)",
        "no_issues": "Sorun yok",
        "no_issues_prompt": "Seçilen zaman aralığında kaydedilmiş bir sorun yok",
        "inflate_difference": "+/- Şişirme (PSI)",
        "total_alerts": "alarmlar",
        "total_warnings": "uyarılar",
        "total_maintenance": "bakım önerileri",
        "total_over_inflations": "aşırı şişirme",
        "total_tyres_checked": "kontrol edilen lastikler",
        "total_vehicles_checked": "kontrol edilen araçlar",
        "total_vehicles": "tüm araçlar",
        "percentage_underinflated": "az şişirilen lastikler",
        "percentage_overinflated": "çok şişirilen lastikler"
    },
    "fleet_km_report": {
        "heading": "Hız ve mesafe verileri. Yüklenmesi biraz zaman alabilir",
        "total_distance_travelled": "Toplam gidilen mesafe (km)",
        "average_speed": "Ortalama Hız (km/h)"
    },
    "fleet_carbon_report": {
        "heading": "Emisyon verisi. Yüklenmesi biraz zaman alabilir",
        "co2_emissions": "CO2 Emisyonu (g/km)",
        "co2_emitted": "CO2 Yayılımı (g)"
    },
    "services": {
        "1": "Enlem",
        "2": "Boylam",
        "3": "Rakım",
        "4": "1A Lastik Basıncı",
        "5": "1B Lastik Basıncı",
        "6": "1C Lastik Basıncı",
        "7": "1D Lastik Basıncı",
        "8": "2A Lastik Basıncı",
        "9": "2B Lastik Basıncı",
        "10": "2C Lastik Basıncı",
        "11": "2D Lastik Basıncı",
        "12": "3A Lastik Basıncı",
        "13": "3B Lastik Basıncı",
        "14": "3C Lastik Basıncı",
        "15": "3D Lastik Basıncı",
        "16": "4A Lastik Basıncı",
        "17": "4B Lastik Basıncı",
        "18": "4C Lastik Basıncı",
        "19": "4D Lastik Basıncı",
        "20": "5A Lastik Basıncı",
        "21": "5B Lastik Basıncı",
        "22": "5C Lastik Basıncı",
        "23": "5D Lastik Basıncı",
        "24": "6A Lastik Basıncı",
        "25": "6B Lastik Basıncı",
        "26": "6C Lastik Basıncı",
        "27": "6D Lastik Basıncı",
        "28": "Kilometre sayacı",
        "29": "Hız",
        "30": "Yakıt seviyesi",
        "31": "Yakıt seviyesi uyarısı",
        "32": "Fren balatası uyarısı",
        "33": "Motor soğutma suyu seviyesi uyarısı",
        "34": "Motor soğutma suyu sıcaklığı uyarısı",
        "35": "Motor yağ seviyesi uyarısı",
        "36": "Akü uyarısı",
        "37": "Motor soğutma suyu sıcaklığı",
        "40": "Yakıt seviyesi",
        "41": "AdBlue seviyesi",
        "42": "Motor yağı seviyesi",
        "46": "Servis aralığı"
    },
    "vehicle-emissions": {
        "fuel-used": "Tüketilen Yakıt (Litre)",
        "carbon-emitted": "CO2 yayılımı (kg)",
        "emissions-since": "{0} tarihinden bu yana emisyonlar",
        "distance-travelled": "Gidilen Mesafe (km)"
    },
    "organisation-base-card": {
        "show-users": "Kullanıcıları Göster",
        "hide-users": "Kullanıcıları Gizle",
        "your-organisation": "Organizasyonun",
        "no-login": "Giriş kaydı bulumamadı",
        "no-users": "Organizasyonda hiç kullanıcı yok",
        "last-seen": "Son Görülme {0}"
    },
    "organisation_settings": {
        "no_profile_picture": "Profil fotoğrafı yok",
        "setup_heading": "Gelişmiş Organizasyon",
        "setup_body": "RL Automotive'e gelecek talep üzerine logolar ve tema özelleştirmeleri de dahil olmak üzere gelişmiş organizasyon özelleştirmeleri sağlanabilir.",
        "successfully_updates": "Profil başarıyla güncellendi",
        "alerts_subheading": "{username}'in ilişkili olduğu araçlar",
        "no_vehicles_content": "Bu kullanıcı herhangi bir araçla ilişkili değil",
        "view_website": "Websitesini göster"
    },
    "errors": {
        "generic_unexpected_error": "Beklenmeyen bir hata oluştu",
        "failed_to_load_alert": "Araçlar sayfası yüklenemedi. Lütfen daha sonra tekrar deneyin",
        "failed_to_update_telematics_unit": "Telematik ünitesi güncellenemedi. Lütfen daha sonra tekrar deneyin",
        "failed_to_load_telematics_units": "Telematik üniteleri yüklenemedi. Lütfen daha sonra tekrar deneyin",
        "failed_to_create_telematics_unit": "Telematik Ünitesi oluşturulamadı, lütfen yapılandırmayı kontrol edin ve tekrar deneyin",
        "failed_to_delete_telematics_unit": "Telematik ünitesi silinemedi. Lütfen daha sonra tekrar deneyin!",
        "update_profile_error": "Profil güncellenirken beklenmeyen bir hata oluştu",
        "delete_user_error": "Kullanıcı silinemedi"
    },
    "colours": {
        "black": "Siyah",
        "blue": "Mavi",
        "green": "Yeşil",
        "yellow": "Sarı",
        "orange": "Turuncu",
        "red": "Kırmızı"
    },
    "pages": {
        "manage_reports": "Raporları Yönet",
        "manage_sim": "SIM Kartları Yönet",
        "edit_sim": "SIM Kartı Düzenle"
    },
    "navbar": {
        "kit_menu": "Kit menüsünü aç",
        "user_menu": "Kullanıcı menüsünü aç",
        "vehicles": "Araçlar",
        "fleets": "Filolar",
        "sensors": "Sensörler",
        "reports": "Raporlar",
        "kit": "Kit",
        "sim_cards": "SIM Kart",
        "telematics_boxes": "Telematik Kutular",
        "settings": "Hesap Ayarları",
        "logout": "Çıkış yap"
    },
    "landing": {
        "quick_actions": "Hızlı aksiyonlar",
        "add_vehicle": "Araç ekle",
        "add_vehicle_prompt": "Başlamak için bir araç ekleyin",
        "add_report": "Rapor ekle",
        "add_report_prompt": "Filolarınız hakkında güncellemeler almak için bir rapor ayarlayın",
        "dashboard": "Bilgi paneli",
        "sensors_on_vehicles": "Araçlardaki sensörler",
        "sensors_on_vehicles_prompt": "√® sensörünü bir araçla eşleştirin",
        "sensors_off_vehicles": "Araçlardaki sensörler kapalı",
        "sensors_off_vehicles_prompt": "Sensörlerin bir araca atanmamış. Bu sensörler veri toplayamayacak",
        "vehicles_prompt": "Organizasyonunuzdaki araçlar ve yönettiğiniz tüm organizasyonlar",
        "latest_changes": "Son değişiklikler"
    },
    "login": {
        "sign_in_message": "Hesabınıza giriş yapın",
        "sign_in": "Giriş yap",
        "incorrect_username_password": "Hatalı kullanıcı adı veya şifre"
    },
    "vehicle_attributes": {
        "co2_emissions": "CO2 Emisyonu (g/km)",
        "type_approval": "Onay türü",
        "euro_status": "Euro Normu"
    },
    "manage_vehicles": {
        "registration": "Plaka",
        "imei": "IMEI",
        "fleet": "Filo",
        "depot": "Depo",
        "show_deactivated": "Aktif olmayan araçları göster/gizle",
        "show_archived": "Arşivlenmiş araçları göster/gizle",
        "add_vehicle": "Araç Ekle",
        "total_vehicles": "Tüm Araçlar",
        "details": "Lastik Bilgileri",
        "no_imei": "IMEI yok",
        "alert_level": "Alarm Seviyesi",
        "open_map": "Haritayı aç",
        "no_results": "Sonuç bulunamadı",
        "no_results_subheading": "Arama kriterlerinize uyan araç bulunamadı",
        "no_vehicles": "Araç bulunamadı",
        "no_vehicles_subheading": "Şu anda organizasyonunuzda hiçbir araç yok. Bir tane oluşturmak için \"Araç Ekle\"ye tıklayın",
        "sort_by": "Sırala",
        "tyre_maintenance": "Lastik bakımı"
    },
    "edit_vehicle": {
        "vehicles": "Araçlar",
        "overview": "Genel bakış",
        "logs": "Kayıtlar",
        "checklist": "Kontrol listesi",
        "mot_history": "MOT geçmişi"
    },
    "vehicle_overview": {
        "vehicle_alerts": "E-posta Uyarıları",
        "speed": "Hız",
        "engine_capacity": "Motor Hacmi",
        "revenue_weight": "Boş ağırlık",
        "vehicle_alerts_description": "Bu araçta bir sorunla karşılaşırsanız e-posta uyarıları alın.",
        "environmental_breakdown": "Çevresel şartlarda bozulma",
        "euro_rating_title": "Euro derecelendirmesi yok",
        "euro_rating_subtitle": "Bu bölümü görüntülemek için lütfen Konfigürasyonlar'da aracın EURO derecesini ayarlayın.",
        "add_config_error": "Lütfen sensör eklemeye başlamak için bu araca bir konfigürasyon verin",
        "archived": "Arşivlendi",
        "deactivated": "Aktive",
        "unarchived": "Devre dışı bırakıldı",
        "thresholds": "Eşikler",
        "below_recommended": "Tavsiye edilenin altında",
        "sr_enter_edit_mode": "Araç detayları için düzenleme moduna girin",
        "sr_exit_edit_mode": "Araç detayları için düzenleme modundan çıkın",
        "type": "Tip",
        "environmental": "Çevresel",
        "manufacturer": "Üretici",
        "model": "Model",
        "chassis_type": "Şasi tipi",
        "fuel_type": "Yakıt tipi",
        "engine_type": "Motor tipi",
        "mot_due": "MOT tarihi",
        "fleet": "Filo",
        "depot": "Depo",
        "imei": "IMEI",
        "configuration": "Konfigürasyon",
        "application": "Uygulama",
        "emergency_contact": "Acil İletişim Kişisi",
        "country": "Ülke",
        "torque": "Tork",
        "axle": "Aks",
        "sr_enter_edit_axle_mode": "Aks için düzenleme moduna girin",
        "sr_exit_edit_axle_mode": "Aks için düzenleme modundan çıkın",
        "rim_size": "Jant ölçüsü",
        "tyre_size": "Lastik ölçüsü",
        "minimum_weight": "Minimum ağırlık",
        "maximum_weight": "Maksimum ağırlık",
        "recommended_pressure": "Önerilen Basınç ({unit})",
        "recommended_temperature": "Önerilen Sıcaklık ({unit})",
        "maintenance_threshold": "Bakım Eşiği",
        "maintenance_threshold_description": "Bakım durumunu aktif eden eşik",
        "warning_threshold": "Uyarı Eşiği",
        "warning_threshold_description": "Uyarı durumunu aktif eden eşik",
        "critical_threshold": "Tehlike Eşiği",
        "critical_threshold_description": "Tehlike durumunu aktif eden eşik",
        "maintenance_level": "Bakım Seviyesi ({unit})",
        "warning_level": "Uyarı Seviyesi ({unit})",
        "danger_level": "Tehlike Seviyesi ({unit})",
        "submit": "Onayla",
        "sensors": "Sensörler",
        "assigned_to": "Atanmış",
        "last_seen": "Son görülme",
        "no_data": "Seçilen zaman dilimi içerisinde veri raporlanmadı",
        "please_check": "Daha fazla bilgi için lütfen araç kayıtlarını kontrol edin",
        "temp_over_time": "Zaman içindeki Sensör Sıcaklığı/Basıncı",
        "current_pressure": "Anlık Basınç",
        "current_cold_pressure": "Anlık Soğuk Basınç",
        "current_temp": "Anlık Sıcaklık",
        "time_to_critical": "Kritik zaman",
        "recommended_tyre_make": "Önerilen Lastik Üreticisi",
        "recommended_tyre_pattern": "Önerilen Lastik Deseni",
        "rim_type": "Jant tipi",
        "tyre_condition": "Lastik durumu",
        "new_tread_depth": "Yeni diş derinliği",
        "gps_unavailable": "GPS kullanılamıyor",
        "gps_fix_unavailable": "GPS düzeltmesi yok",
        "current_location": "Anlık lokasyon",
        "no_sensors": "Bu araca şu anda takılı sensör yok",
        "kilometres_travelled": "Gidilen Kilometre",
        "kilometres_per_hour": "Saate Kilometre",
        "kilometers_hour": "Kilometre/Saat",
        "schematic_view": "Şematik görünüm",
        "list_view": "Liste görünümü",
        "battery_unavailable": "Batarya mevcut değil",
        "tyre_make": "Lastik Üreticisi",
        "tyre_pattern": "Lastik Deseni",
        "estimated_co2": "Toplam tahmini CO2 emisyonları (kg)",
        "total_emissions": "Toplam emisyonlar",
        "deactivate_vehicle": "Aracı devre dışı bırak",
        "spannered_off": "Bakım modu",
        "on": "açık",
        "off": "kapalı"
    },
    "attach_sensor_modal": {
        "checksum_calculator": "Kontrol sonucu",
        "create_title": "Bu pozisyonda yeni bir sensör oluşturun",
        "id": "ID",
        "type": "Tip",
        "create_sensor": "Sensör oluştur ve bağla",
        "attach_title": "Sensör seç ve bağla",
        "no_unassigned_sensors": "Bağlanmamış sensör bulunamadı",
        "no_unassigned_subheading": "Bir sensörü araçtan kaldırın veya yenisini oluşturmak için artı düğmesine tıklayın",
        "show_assigned": "Bağlanmış sensörleri göster",
        "attach_sensor": "Sensör bağla",
        "missing_sensor": "Sensör yok"
    },
    "delete_sensor_modal": {
        "delete_message": "Bu eylem geri alınamaz ve bu sensörü sistemden kalıcı olarak siler. Sensörü yeniden kullanmak için bağlantısını kaldırmak istiyorsanız, lütfen bu işlemi iptal edin ve bunun yerine bağlantıyı kaldır düğmesini seçin."
    },
    "vehicle_logs": {
        "limit": "Limit",
        "sort": "Sırala",
        "newest": "En yeni",
        "oldest": "En eski",
        "filter": "Filtre",
        "any": "Herhangi",
        "decode_success": "Kod çözme başarılı",
        "decode_error": "Kod çözme başarısız",
        "configuration_error": "Konfigürasyon hatası",
        "since": "İtibaren",
        "now": "Şimdi",
        "reason": "Sebep",
        "sensor_id": "Sensör ID",
        "temperature": "Sıcaklık",
        "pressure": "Basınç",
        "speed": "Hız",
        "distance": "Mesafe",
        "no_logs": "Görüntülenen kayıt yok",
        "no_logs_subheading": "Lütfen araç plakasını kontrole ediniz.",
        "tpm_success_message": "TPM paketi başarıyla ayrıştırıldı",
        "speed_dist_success_message": "Hız ve mesafe paketi başarıyla ayrıştırıldı",
        "sensor_data_error_message": "Paketin kodu çözülürken hata oluştu",
        "lantronix_data_error_message": "Lütfen Telematik Ünitesinin Konfigürasyonunu kontrol edin",
        "sensor_handshake_message": "Handshake kodu başarıyla çözüldü",
        "sensor_error_message": "Bir şeyler ters gitti (tanımlanamayan sensör hatası)",
        "unrecognized_external_sensor_pakcet": "Tanınmayan harici sensör paketi alındı",
        "sensor_flag_set": "Sensör durum bayrağı ayarlandı, sensör son 60 dakikada veri bildirmedi"
    },
    "vehicle_checklist": {
        "checklist": "Kontrol listesi",
        "issued_by": "Veren",
        "created_at": "Kuruldu",
        "checklists_not_supported": "Kontrol listeleri şu anda bu araç türü için desteklenmiyor",
        "return_to_checklists": "Kontrol listelerine geri dön",
        "return_to_previous_recordings": "Önceki kayıtlara geri dön",
        "add_checklist": "Kontrol listesi ekle",
        "reset": "Reset",
        "brakes": "Frenler",
        "horn_steering": "Korna/Direksiyon",
        "horn_and_steering": "Korna/Direksiyon",
        "lights": "Işıklar",
        "mirror_glass": "Ayna/Cam",
        "seat_belts": "Emniyet Kemerleri",
        "washers_wipers": "Cam yıkama/Silecekler",
        "battery": "Akü",
        "fluids_fuel_oil": "Sıvılar/Yakıt/Yağ",
        "bodywork_doors": "Kaporta/Kapılar",
        "bodywork_and_doors": "Kaporta/Kapılar",
        "exhaust": "Egzoz",
        "tyres_wheels": "Lastikler/Tekerler",
        "tyres_and_wheels": "Lastikler/Tekerler",
        "load": "Yük",
        "towbar_taillift": "Çeki Demiri/Kuyruk Lifti",
        "towbar_or_tail_lift": "Çeki Demiri/Kuyruk Lifti",
        "van_check": "Van kontrolü",
        "previous_recordings": "Önceki kayıtlar",
        "faults": "Hatalar",
        "no_recordings": "Bu araç için daha önce yapılmış kayıt bulunmamaktadır",
        "mirrors_cameras_and_glass": "Ayna Kameraları ve Cam",
        "mirrors_and_glass": "Ayna Kameraları ve Cam",
        "windscreen_wipers_and_washers": "Cam Silecekleri ve Fıskiyeleri",
        "washers_and_wipers": "Cam Silecekleri ve Fıskiyeleri",
        "warning_lights": "Uyarı ışıkları",
        "steering_and_electronic_stability_control": "Direksiyon ve ESP",
        "horn": "Korna",
        "breaks_and_air_build": "Frenler ve Fren Havası",
        "height_marker": "Yükseklik Belirteci",
        "seat_belts_and_cab_interior": "Emniyet Kemeri ve Kabin Trimleri",
        "security_and_condition_of_cab_doors_steps": "Kabin kapı basamaklarının durumu ve güvenliği",
        "brake_lines": "Fren hatları",
        "electrical_connections": "Elektrik bağlantıları",
        "coupling_security": "Kapı kilidi",
        "load_security": "Yük güvenliği",
        "number_plate": "Araç plakası",
        "reflectors_and_lights": "Reflektörler ve Işıklar",
        "markers": "İşaretleyiciler",
        "ancillary_equipment": "Yardımcı ekipmanlar",
        "lights_indicators_and_side_repeaters": "Lambalar",
        "fuel_oil_leaks": "Yakıt/yağ sızıntısı",
        "security_and_condition_of_body_wings": "Hidrolik kapakların durumu ve güvenliği",
        "battery_security_and_condition": "Akü durumu ve güvenliği",
        "diesel_exhaust_fluid_adblue": "Dizel egzoz sıvısı AdBlue",
        "excessive_engine_exhaust_smoke": "Fazla egzoz gazı",
        "aleternative_fuel_systems_and_isolation": "Alternatif Yakıt Sistemleri ve İzolasyon",
        "spray_suppression": "Çamurluklar",
        "tyres_and_wheel_fixing": "Tekerlek bağlantıları",
        "high_voltage_emergency_cut_off_switch": "Yüksek Voltaj Acil Durum Switchi",
        "cab_mirrors_seatbelts_glass": "Kabin, Aynalar, Emniyet Kemerleri ve Camlar",
        "cab_mirror_seat_belt_and_glass": "Kabin, Aynalar, Emniyet Kemerleri ve Camlar",
        "horn_and_gauges": "Korna ve göstergeler",
        "turn_signals": "Sinyaller",
        "backup_lights_and_alarm": "Yedek Işıklar ve Alarm",
        "fire_extinguisher_condition": "Yangın Söndürücü Durumu",
        "windshield_wipers_and_fluid": "Cam Silecekleri ve Suyu",
        "fuel_levels": "Yakıt seviyesi",
        "engine_oil": "Motor yağı",
        "transmission_fluid": "Şanzıman yağı",
        "brake_fluid": "Fren hidroliği",
        "cooling_system_fluid": "Soğutma suyu",
        "oil_leak_lube": "Yağ Sızıntısı/Yağlama",
        "brake_condition": "Fren durumu",
        "tyres_or_tracks": "Lastikler veya Paletler",
        "tires_or_tracks": "Lastikler veya Paletler",
        "hydraulic_oil": "Hidrolik Yağı",
        "hose_condition": "Sıvı hortumları durumu",
        "coupling_devices_and_connectors": "Kilit cihazları ve bağlantıları",
        "exhaust_system": "Egzoz sistemi",
        "blade_bloom_ripper": "Tarım aletleri durumu",
        "no_notes": "Bu kayıtla ilgili hiçbir not yok",
        "licence_lamp": "Plaka Işığı",
        "fift_wheel_grease": "Pleyt Gresi",
        "electrical_connections_and_terminals": "Elektrik bağlantıları ve terminalleri",
        "dot_reflective_markings": "DOT reflektörü işaretleri",
        "access_ramps": "Rampalar",
        "brake_lamps_and_reflectors": "Fren lambaları ve reflektörleri",
        "fasteners": "Emniyet Kemeri Tokaları",
        "ragged_sharp_edges_dammage_body_condition": "Pürüzlü keskin kenarlar/Gövde durumu",
        "trailer_sides_roof_damage": "Treyler yanları/tavan hasarı",
        "latches_gates_opening_devices": "Kilitler/Kapılar/Açma Cihazları",
        "safety_kits": "Güvenlik kiti",
        "adr_kit": "ADR kiti",
        "fridge_unit": "Frigo Ünitesi",
        "fridge_unit_fuels": "Frigo Ünitesi Yakıtı",
        "straps": "Kayışlar",
        "tailift_condition": "Kuyruk lifti durumu",
        "tailift_operation": "Kuyruk lifti operasyonu",
        "bumpers_guards": "Tamponlar",
        "additional_lamps": "Ek lambalar",
        "truck_and_trailer_cables_brake_connections": "Fren kabloları bağlantıları",
        "air_suspension_leaks": "Havalı Süspansiyon Kaçağı",
        "spare_wheel_and_carrier": "Stepne ve Kriko",
        "hooses_and_pipes": "Hortumlar ve Borular",
        "tyre_thread_depth": "Lastik Diş Derinliği",
        "tyre_condition": "Lastik Durumu",
        "tachograph": "Takograf",
        "adblue_level_ok": "AdBlue seviyesi OK",
        "steering": "Direksiyon",
        "fuel_filter_cap": "Yakıt deposu kapağı",
        "cameras": "Kameralar",
        "load_secure_restraints": "Yük sabitleyicileri",
        "doors": "Kapılar",
        "loading_bar": "Yükleme aparatları",
        "three_tacho_rolls": "Üç Tako Rulosu",
        "sack_truck": "El Arabası",
        "wipes": "Bezler",
        "first_aid_kit": "İlk yardım kiti",
        "fire_extinguisher": "Yangın Söndürücü",
        "breakdown_booklet": "Arıza El Kitabı",
        "incident_form": "Kaza Tutanağı",
        "safety_check_sheet": "Emniyet kontrol listesi",
        "van_dimensions": "Van ölçüleri",
        "no_valuables_on_sight": "Görünür değer yok",
        "fuel_on_return": "Dönüş yakıt seviyesi",
        "fuel_reciept": "Yakıt Makbuzu",
        "wheel_nut_indicators": "Tekerlek Bijonu Göstergeleri",
        "wheel_torque_settings": "Tekerlek Tork Ayarı",
        "attach_image": "Resim ekle",
        "media": "Medya"
    },
    "mot_history": {
        "completed_date": "Tamamlanma Tarihi",
        "expiry_date": "Son Kullanma Tarihi",
        "odometer_value": "Kilometre sayacı",
        "test_number": "Test numarası",
        "no_vehicle_found_error": "Bu plakada herhangi bir MOT testi bulunamadı",
        "test_location": "Test yeri",
        "major_defects": "Büyük Kusurlar",
        "first-mot-due": "İlk MOT tarihi",
        "date-registered": "Plaka verilen tarih"
    },
    "new_vehicle": {
        "add_vehicle": "Araç ekle",
        "basic_information_title": "Temel Bilgiler",
        "basic_information_prompt": "Araçla İlgili Temel Bilgiler",
        "registration": "Plaka",
        "check_dvla": "DVLA kontrol et",
        "dvla_duplicate_vehicle": "Bu plaka ile zaten bir araç var",
        "manufacturer": "Üretici",
        "model": "Model",
        "depot": "Depo",
        "depots": "Depolar",
        "fleets": "Filolar",
        "missing_sensor": "Sensörsüz",
        "sensors": "Sensörler",
        "engine_type": "Motor tipi",
        "fuel_type": "Yakıt tipi",
        "map": "Harita",
        "mot_due": "MOT tarihi",
        "chassis_type": "Şasi tipi",
        "vehicle_type": "Araç tipi",
        "imei": "IMEI",
        "application": "Uygulama",
        "torque": "Tork",
        "sensor_information_title": "Sensör bilgisi",
        "sensor_information_prompt": "Sensör sayısı ve tipleri ile ilgili bilgi",
        "configuration": "Konfigürasyon",
        "sensor_manifest": "Sensör Manifestosu",
        "create_without_sensors": "Sensörler olmadan oluştur",
        "create_with_sensors": "Sensör ile birlikte oluştur",
        "list": "Araçlar"
    },
    "vehicle_download": {
        "pressure": "Lastik Basıncı",
        "cold_pressure": "Lastik Soğuk Basıncı",
        "temperature": "Lastik Sıcaklığı",
        "select_all": "Hepsini seç",
        "deselect_all": "Tüm seçimleri kaldır",
        "subheading": "Verileri indirmek istediğiniz unsurları ve zaman aralığını belirtin. İndirmeler biraz zaman alabilir, indirme başladıktan sonra bu sayfadan ayrılabilir ve dosya tamamlandığında indirmeler klasörünüzde görebilirsiniz."
    },
    "manage_fleets": {
        "add_fleet_contact": "{fleet}'e iletişim kişisi ekle",
        "contact_name": "İletişim kişisi",
        "add_contact": "İletişim kişisi ekle",
        "add_fleet": "Filo ekle",
        "total_fleets": "Tüm Filolar",
        "defaults": "Varsayılan",
        "defaults_prompt": "Bu değerler, bu filoda oluşturulacak tüm yeni araçlar için varsayılan eşikler olarak kullanılacaktır.",
        "create_depot_prompt": "Şuna depo ekle",
        "depots": "{ count } Depo | { count } Depolar",
        "created_on": "{ date } tarihinde oluşturuldu",
        "add_depot": "Depo ekle",
        "no_fleets": "Filo yok",
        "no_depots": "Depo yok",
        "no_fleets_prompt": "Şu anda organizasyonunuzda filo yok. Bir tane oluşturmak için \"Filo Ekle\"ye tıklayın",
        "no_depots_prompt": "Bu filoda herhangi bir depo bulunmuyor. Bir depo oluşturmak için \"Depo Ekle\" düğmesine tıklayın",
        "duplicate_fleet_error": "Bu isimle bir filo zaten var",
        "duplicate_depot_error": "Bu isimle bir depo zaten var",
        "confirm_delete_depot_modal_text": "{depot} öğesini silmek istediğinizden emin misiniz?",
        "confirm_delete_fleet_modal_text": "{fleet} öğesini silmek istediğinizden emin misiniz?",
        "sensors_prompt": "Aşağıdaki araçlarda bir veya daha fazla sensör eksik",
        "empty_prompt_header": "Tüm araçlar başarıyla konfigüre edildi",
        "empty_prompt_subtitle": "Bu filoda sensörü eksik olan bir araç yok",
        "subscribe": "İlgili",
        "unsubscribe": "İlgili değil",
        "subscribe_prompt": "Bu filodaki tüm araçlara bağlan",
        "unsubscribe_prompt": "Bu filodaki tüm araçlarla bağlantıyı kes",
        "colour": "Renk",
        "definition": "Tanım",
        "black_definition": "Veri yok",
        "blue_definition": "Son 12 saat içinde veri yok",
        "green_definition": "Sorun yok",
        "yellow_definition": "Bakım eşiğinin altında",
        "orange_definition": "Uyarı eşiğinin altında",
        "red_definition": "Tehlike eşiğinin altında",
        "subscribe_failed": "Bu filoya bağlanılamadı",
        "subscribe_success": "Filodaki tüm araçlara başarıyla bağlanıldı",
        "unsubscribe_failed": "Filodaki tüm araçlara bağlantı kesilemedi",
        "unsubscribe_success": "Filodaki tüm araçlara bağlantı kesildi",
        "delete_fleet": "Filoyu sil",
        "delete_failed": "Filo silinemedi",
        "update_depot": "Depoyu güncelle",
        "show_map": "Haritayı göster",
        "hide_map": "Haritayı gizle"
    },
    "sensors": {
        "add_sensor": "Sensör ekle",
        "sensor_type": "Sensör tipi",
        "bound": "Sınır",
        "wheel_position": "Teker pozisyonu",
        "on_vehicle": "Araç üzerinde",
        "not_on_vehicle": "Araç üzerinde değil",
        "no_vehicle": "Araç yok",
        "updated_on": "Güncellendi",
        "no_wheel": "Teker yok",
        "same_id_error": "Bu ID ile bir sensör zaten var",
        "no_reg_error": "Bu plaka ile bir araç bulunamadı",
        "axle_number": "Aks numarası",
        "wheel_number": "Teker numarası",
        "no_data_recorded": "Kaydedilmiş veri yok",
        "failed_to_load": "Sensörü yüklemede hata",
        "no_sensors": "Sensör yok",
        "no_sensors_subheading": "Şu anda organizasyonunuzda sensör yok. Bir tane oluşturmak için \"Sensör Ekle\"ye tıklayın",
        "no_results_subheading": "Arama kriterlerinize uyan sensör bulunamadı",
        "tyre_size": "Lastik Ölçüsü",
        "tyre_make": "Lastik Üreticisi",
        "tyre_pattern": "Lastik Deseni"
    },
    "reports": {
        "add_report": "Rapor ekle",
        "reports": "Raporlar",
        "add report": "Rapor ekle",
        "schedule": "Program",
        "send_now": "Şimdi gönder",
        "edit_report": "Raporu düzenle",
        "emails": "E-posta(lar)",
        "language": "Dil",
        "ima": "Bakım Öncesi Muayene (PMI)",
        "km": "Kilometre (km)",
        "co2": "Emisyon (CO2)",
        "maintenance": "Bakım",
        "vehicle_list": "Araç Listesi",
        "basic_information": "Temel Bilgiler",
        "basic_information_prompt": "Oluşturmak istediğiniz rapor türünü seçin ve gerekli ayrıntıları girin",
        "frequency": "Sıklık",
        "frequency_prompt": "Raporu ne sıklıkta göndermek istediğinizi seçin",
        "day": "Gün",
        "daily": "Günlük",
        "hour": "Saat",
        "failed_to_load": "Rapor yüklenemedi. Lütfen daha sonra tekrar deneyin",
        "error_sending": "Rapor gönderilirken bir hata oluştu",
        "confirmation_send": "Rapor başarıyla gönderildi",
        "delete_error": "Rapor silinirken bir hata oluştu",
        "no_reports": "Rapor yok",
        "no_reports_subheading": "Şu anda organizasyonunuzda hiçbir rapor yok. Bir tane oluşturmak için \"Rapor Ekle\"ye tıklayın"
    },
    "sim": {
        "add_sim": "SIM Kart ekle",
        "search_iccid": "ICCID ara",
        "iccid_prompt": "SIM Kart için benzersiz tanımlayıcı",
        "msisdn_prompt": "Ülke kodunu da içeren 10-14 haneli telefon numarası (örneğin Türkiye'de 90)",
        "provider": "Sağlayıcı",
        "send_sms": "SMS gönder",
        "send_sms_prompt": "Şu kişiye SMS gönder",
        "message": "Mesaj",
        "sms_sent": "SMS başarıyla gönderildi",
        "imsi_description": "Mobil İstasyon ISDN Numarası",
        "provider_description": "SIM kartını veren sağlayıcı. SIM sağlayıcınız listelenmemişse lütfen destek ile iletişime geçin.",
        "unable_to_delete": "SIM Kart silinemiyor, lütfen destek ekibiyle iletişime geçin.",
        "unable_to_edit": "SIM Kart düzenlenemiyor, lütfen destek ekibiyle iletişime geçin.",
        "failed_to_create": "SIM Kart oluşturulamıyor, lütfen konfigürasyonu kontrol edin ve tekrar deneyin.",
        "no_sim_cards": "SIM Kart yok",
        "no_sim_cards_subheading": "Şu anda organizasyonunuzda SIM Kart yok. Bir tane oluşturmak için \"SIM Kart Ekle\"ye tıklayın"
    },
    "telematics": {
        "edit_telematics": "Telematik Ünitesini düzenle",
        "no_update_information": "Güncelleme bilgisi yok",
        "add_telematics": "Telematik Kutu Ekle",
        "search_imei": "IMEI ara",
        "go-to-vehicle": "Araca git",
        "software": "Yazılım",
        "hardware": "Donanım",
        "main_port": "Ana İstasyon",
        "accessory_port": "Yedek İstasyon",
        "device_name": "Cihaz ismi",
        "remove": "Telematik Ünitesini kaldır",
        "failed_to_load": "Telematik Ünitesini yüklemede hata",
        "tyre-control-box-id": "Lastik Kontrol Kutusu ID",
        "no_telematics_boxes": "Telematik Kutu yok",
        "process-from-configurator": "Konfigüratörden İşlem",
        "no_telematics_boxes_subheading": "Şu anda organizasyonunuzda Telematik kutusu yok. Bir tane oluşturmak için \"Telematik Kutusu Ekle\"ye tıklayın",
        "online": "Çevrimiçi",
        "offline": "Çevrimdışı",
        "move-telematics-unit": "Telematik Ünitelerini Taşı"
    },
    "settings": {
        "profile": "Profil",
        "old_password": "Eski şifre",
        "new_password": "Yeni şifre",
        "confirm_password": "Şifreyi onayla",
        "update_password": "Şifreyi güncelle",
        "profile_prompt": "Hesabınızla ilgili temel bilgiler",
        "password_prompt": "Şifreni değiştir",
        "first_name": "İlk ismi",
        "last_name": "Son ismi",
        "password": "Şifre",
        "locale": "Yerel",
        "locale_prompt": "Websitesinde bilgilerin nasıl görüntüleneceğini değiştirin. Burada seçilen dil ve sıcaklık birimleri raporlara da yansıtılacaktır, örneğin IMA raporları.",
        "language": "Dil",
        "pressure_unit": "Basınç ünitesi",
        "temperature_unit": "Sıcaklık ünitesi",
        "time_zone": "Zaman Dilimi",
        "coming_soon": "Yakında",
        "update_settings": "Ayarları güncelle",
        "alerts": "Alarmlar",
        "no_alerts": "Alarm yok",
        "alerts_subtitle": "Bu araçtan alarm e-postaları alacaksınız",
        "organisation_subtitle": "Organizasyonunuz ile ilgili ayarlar ve bilgiler.",
        "no_active_subscriptions_placeholder": "Aktif araç bağlantınız bulunmamaktadır. Bağlantılar araç menüsünden aktifleştirilebilir.",
        "add_user": "Kullanıcı ekle",
        "username_error": "Kullanıcı adı veya E-posta daha önce alınmış.",
        "email_error": "Lütfen geçerli bir E-posta adresi girin.",
        "current_organisation": "Anlık (Default)",
        "confirm_delete_user_modal_text": "{user} kullanıcısını silmek istediğinizden emin misiniz?"
    },
    "not_found_page": {
        "title": "Sayfa bulunamadı",
        "subtitle": "Lütfen adres satırındaki URL'yi kontrol edin ve yeniden deneyin.",
        "return_home": "Ana ekrana dön"
    },
    "server_error_page": {
        "title": "Dahili Sunucu Hatası",
        "subtitle": "Sunucu bir hatayla karşılaştı ve isteğinizi tamamlayamadı.",
        "return_home": "Ana ekrana dön"
    },
    "application": {
        "logistics": "Lojistik",
        "mixed_service": "Karışık kullanım",
        "waste": "Atık",
        "food_distribution": "Gıda",
        "food_waste": "Gıda atığı",
        "psv": "Toplu Taşıma",
        "off_highway": "Otoyol Dışı",
        "urban_last_mile": "Şehiriçi",
        "other": "Diğer"
    },
    "fuel": {
        "petrol": "Benzin",
        "diesel": "Dizel",
        "electric": "Elektrik",
        "hybrid": "Hibrit",
        "biofuel": "Biyoyakıt",
        "hydrogen_fuel_cell": "Hidrojen Yakıt Hücresi",
        "compressed_natural_gas": "CNG",
        "liquified_petroleum_gas": "LPG",
        "non_applicable": "Yok"
    },
    "vehicle_type": {
        "bus": "Otobüs",
        "car": "Otomobil",
        "crane": "Vinç",
        "digger": "Kazıcı",
        "dumper": "Damperli Kamyon",
        "loader": "Kepçe",
        "tractor": "Çekici",
        "trailer": "Treyler",
        "truck": "Kamyon",
        "truck_trailer": "Kamyon ve Treyler",
        "tug": "Tow Tractor",
        "van": "Van"
    },
    "tyre_condition": {
        "new": "Yeni",
        "re-mould": "Kaplanmış",
        "re-grooved": "Diş Açılmış"
    },
    "days": {
        "monday": "Pazartesi",
        "tuesday": "Salı",
        "wednesday": "Çarşamba",
        "thursday": "Perşembe",
        "friday": "Cuma",
        "saturday": "Cumartesi",
        "sunday": "Pazar"
    },
    "dvla": {
        "no_registration": "DVLA'yı kontrol etmek için lütfen bir plaka girin",
        "failed_to_create": "Araç oluşturulamadı",
        "not_found_error": "Araç bulunamadı"
    },
    "abbreviations": {
        "kilobytes": "kilobyte"
    },
    "footer": {
        "powered_by_text": "RL Automotive tarafından oluşturuldu"
    },
    "telematics-unit-vehicle-create-modal": {
        "imei-subtitle": "IMEI sadece rakam içermelidir",
        "no-sim-card": "SIM Kart yok",
        "create-sim-card-subtitle": "Aşağıda listelenen SIM kartlarda bulamıyorsanız, bir tane oluşturmanız gerekir",
        "no-matching-search": "Bu arama kriterine uyan SIM Kart bulunamadı",
        "search-results-hidden": "Sonraki arama sonuçları gizlendi"
    },
    "app-error-modal": {
        "extra-information": "İlave bilgi"
    },
    "components": {
        "pagination": {
            "pagination-text": "{1} sayfadan {0}. sayfa gösteriliyor",
            "total-results-text": "Tüm sonuçlar"
        },
        "manage-vehicles": {
            "hide-deactivated": "Gizleme özelliği kaldırıldı",
            "show-deactivated": "Gösterme özelliği kaldırıldı",
            "show-summary": "Özet göster",
            "show-map": "Haritayı göster"
        },
        "telematics-unit-overview": {
            "unit-attached-to": "Telematic Ünite şuraya bağlandı:",
            "unit-not-connected": "Bu ünite şu anda bir araca bağlı değil. Bu üniteden alınan veriler işlenmeyecektir."
        },
        "organization-integrations-itdn": {
            "independant-tyre-data-network": "Bağımsız Lastik Bayii Ağı",
            "integration-active": "Entegrasyon aktif",
            "click-to-activate-integration": "Entegrasyonu aktif etmek için tıklayın",
            "register-itdn-integration": "ITDN entegrasyonuna kayıt olun"
        },
        "organization-integrations": {
            "integrations": "Entegrasyonlar"
        },
        "vehicle-map": {
            "last-known-position": "Bilinen son konum",
            "show-low-emission-zones": "Düşük emisyon bölgelerini göster",
            "about-lez": "LEZ hakkında",
            "penalty-charges": "Cezai yaptırımlar",
            "show-service-vehilces": "Servis araçlarını göster"
        },
        "vehicle-overview": {
            "download-vehicle-data": "Araç verisini indir"
        },
        "vehicle-data": {
            "vehicle-data-explorer": "Araç Verisi Bulucusu"
        },
        "vehicle-overview-mot": {
            "mot-status": "MOT/Geri Çağırma Durumu"
        },
        "vehicle-overview-contact-itdn": {
            "contact-itdn": "ITDN ile iletişime geçin",
            "submit": "ITDN işi gönder",
            "cancel_prompt": "Bir işi iptal etmek için lütfen iş referans numaranızla birlikte ITDN ile iletişime geçin."
        },
        "vehicle-overview-notification-config": {
            "manage-notifications": "Bildirimleri yönet"
        },
        "vehicle-overview-events": {
            "event-log": "Durum günlüğü",
            "show-vehicle-events": "Araç Durumlarını Göster"
        },
        "edit-fleet-modal": {
            "update-fleet": "Filoyu güncelle",
            "subheading": "Genel filo bilgilerini güncelle"
        },
        "edit-fleet-move-organization": {
            "update-organization": "Organizasyonu güncelle",
            "subheading": "Bu filoyu başka bir organizasyona taşı"
        },
        "fleet-base": {
            "kilometer_report": "Kilometre raporu",
            "generic-error-message": "Bir şeyler ters gitti",
            "error-modal-title": "Bu filo silinemedi. Lütfen tekrar denemeden önce aşağıdakileri kontrol edin.",
            "error_hints": {
                "move-vehicles": "Tüm araçlar başka bir filoya taşındı",
                "delete-depots": "Tüm depolar bu filodan kaldırıldı",
                "delete-reports": "Bu filo için planlanan tüm raporlar silindi"
            }
        },
        "vehicle-speed-pill": {
            "current-speed": "Anlık Hız {0} Km/h"
        },
        "vehicle-download": {
            "selected-service-count": "{0} servis seçildi"
        },
        "vehicle-distance-pill": {
            "current-distance": "Gidilen Mesafe {0} Km"
        },
        "configurator-connection-status": {
            "unable-to-connect-heading": "Yapılandırıcı istemcisine bağlanılamıyor",
            "unable-to-connect-msg": "Yapılandırıcı istemcisi sunucuyla bağlantı kuramadı. Lütfen internet bağlantınızı kontrol edip tekrar deneyin.",
            "establishing-connection-heading": "Yapılandırıcı istemcisine bağlantı kuruluyor",
            "establishing-connection-msg": "Eğer bu mesaj devam ederse lütfen internet bağlantınızı kontrol edip tekrar deneyin."
        },
        "configurator-pending-messages": {
            "no-pending-messages": "Bekleyen Mesaj Yok...",
            "pending-message-deletion": "Bekleyen mesajlar 1 ay boyunca aktif olunmazsa silinecektir."
        }
    }
}
